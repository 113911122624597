/* General Container */
.service-container {
  padding: 50px;
  background-color: #f9fbff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-overview {
  margin-bottom: 40px;
}

.service-title {
  font-size: 36px;
  font-weight: 700;
  color: #4b2fae;
  margin-bottom: 10px;
}

/* Service Boxes Container */
.service-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px; /* Space between service boxes */
}

/* Individual Service Box */
.service-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;
}

.service-box:hover {
  transform: translateY(-5px); /* Hover effect */
}

.service-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.service-box h3 {
  font-size: 24px;
  font-weight: 600;
  color: #4b2fae;
  margin-bottom: 10px;
}

.service-box p {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.service-box ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.service-box li {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

/* Button Styling for Google Form */
button {
  padding: 12px 24px;
  background-color: #4b2fae;
  color: #ffffff;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

button:hover {
  background-color: #37208c; /* Darker shade on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .service-box {
      width: 100%; /* Make boxes full width on smaller screens */
  }

  .service-boxes {
      flex-direction: column;
      gap: 20px; /* Reduce gap for smaller screens */
  }
}

.service-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
}

.title {
  flex: 1;
  text-align: left;
}

.title h1 {
  font-size: 40px;
  color: #4b2fae;
  margin-bottom: 10px;
}

.title p {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
}

.button-footer {
  margin-top: 15px;
}

.button-footer button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-footer button:hover {
  background-color: #3a229e;
}

.right-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
