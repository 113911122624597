/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem; /* Keep padding fixed to prevent height expansion */
    height: 80px; /* Fixed height for the navbar */
    background-color: #F3F3E0;
}

/* Logo container */
.navbar-left {
    max-height: 100%; /* Ensures logo doesn't expand the navbar height */
    display: flex;
    align-items: center; /* Center the logo vertically */
}

/* Logo image */
.navbar-left img {
    height: 120px; /* Adjust the logo size within the navbar */
    width: auto;
    object-fit: contain; /* Maintain logo's aspect ratio */
}

/* Flex container for the links */
.navbar-right {
    display: flex;
}

/* Styling for navigation links */
.links {
    list-style-type: none;
    display: flex;
    gap: 1.5rem;
    margin: 0;
}

.links li {
    font-size: 1rem;
}

.links a {
    color: black;
    text-decoration: none;
    font-weight: bold;
}

.links a:hover {
    color: gray;
}
