/* Portfolio.css */
.portfolio-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.project-section {
  display: flex;
  align-items: center;
  gap: 40px;
}

.project-section.reverse {
  flex-direction: row-reverse;
}

.project-media {
  flex: 1;
}

.project-video {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.project-content {
  flex: 1;
  padding: 10px;
}

.project-content h2 {
  font-size: 24px;
  color: #4b2fae;
  margin-bottom: 15px;
}

.project-content p {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 15px;
}

.project-link {
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  background-color: #4b2fae;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.project-link:hover {
  background-color: #3a2291;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .project-section {
      flex-direction: column;
  }
  .project-section.reverse {
      flex-direction: column;
  }
}

/* Footer Styles */
.port-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  padding: 20px;
}

.left-text {
  flex: 1;
  text-align: left; /* Align text to the left */
}

.left-text h2 {
  font-size: 36px; /* Adjusted font size for visibility */
  color: #4b2fae;
  margin-bottom: 10px;
}

.left-text p {
  font-size: 16px; /* Slightly smaller font for readability */
  color: #333;
  line-height: 1.5;
}

.port-img {
  flex: 1;
  display: flex;
  justify-content: flex-end; /* Align the image to the right */
  
}

.port-img img {
  max-width: 100%;
  border-radius: 8px;
  width: 400px;
  height: 300px;
  
}

/* Button Styles */
.download-button {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none; /* Remove underline from link */
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #3a2291;
}

.download-button a {
  color: white; /* Keep the link color white */
  text-decoration: none; /* Ensure no underline */
}

.port-title h1{
  color: #4b2fae;
  font-size: 40px;
  text-align: center;
}