/* Home Container */
.home-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    background-color: #f9fbff;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
}

/* Tagline Section */
.tagline {
    max-width: 600px;
}

.tagline h1 {
    font-size: 45px;
    font-weight: 700;
    color: #4b2fae; /* Light blue color */
    margin-bottom: 20px;
}

.tagline h2 {
    font-size: 30px;
    font-weight: 600;
    color: #4b2fae; /* Light blue color */
    margin-bottom: 10px;
}

.tagline p {
    font-size: 22px;
    color: black;
    line-height: 1.6;
    margin-bottom: 30px;
}

/* Image Section */
.right-image img {
    width: 650px;
    height: 500px;
}

/* Button Styling */
/* Button Styling */
/* Explore Button Styling */
.explore-button {
    border-radius: 8px;
    padding: 10px 20px;
    background-color: green; /* Set button color to green */
    color: #fff;
    font-size: 18px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.explore-button:hover {
    background-color: #4b2fae; /* Optional: Add hover effect */
}


/* Service Overview Section */
.service-overview {
    width: 100%; /* Ensures it spans full width below the main content */
    text-align: center;
    margin-top: 40px;
}

.service-overview-title {
    font-size: 32px;
    font-weight: 600;
    color: #4b2fae;
    margin-bottom: 20px;
}

.services-container {
    display: flex;
    justify-content: center;
    gap: 30px; /* Adjusted gap for better spacing */
    margin-top: 20px;
}

.service-box {
    width: 300px;
    background-color: #e8eaf6;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    color: #4b2fae;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.service-box:hover {
    transform: translateY(-5px); /* Add hover effect for interactivity */
}

.service-box h4 {
    font-size: 20px;
    margin-bottom: 8px;
    color: #4b2fae;
}

.service-box p {
    font-size: 14px;
    color: #333;
    line-height: 1.6;
    margin: 0; /* Removes default margins */
}

/* Center Button Section */
.center-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
}

/* About Company Section */
.about-company h2 {
    font-size: 32px;
    font-weight: 600;
    color: #4b2fae;
    margin-bottom: 20px;
    text-align: center;
}

.about-company p {
    text-align: center;
    margin-top: 40px;
    color: black;
    font-size: 20px;
}

/* Center Button Styles */
.center-button button {
    padding: 10px 20px;
    border-radius: 8px;
    background-color: green;
    color: #fff;
    font-size: 18px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.center-button button:hover {
    background-color: #37208c; /* Darker shade on hover */
}
